.navbar {
    border-bottom: 1px solid #E4E4E4;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
}

.frame-icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 66px;
    height: 67px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(123, 214, 38, 0.25);
    border-radius: 10px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.total {
    padding: 15px 11px;
}

.success {
    padding: 6px 11px;
}

.invalid {
    padding: 6px 11px;
}

input:checked~.dot {
    transform: translateX(100%);
    background-color: #FFFFFF;
}

input:checked~.toggle {
    border-color: white;
    background-color: #38A0F8;
}

.icon .tooltip {
    padding: 2px 4px;
    font-size: xx-small;
}

.btn .tooltip {
    padding: 4px 6px;
    font-size: x-small;
}

.date-picker-width {
    width: 37%;
}